// Hovering buttons
const followOnHover = (hovered, following) => {

  const initBorderColor = following.style.borderColor;
  const initBackgroundColor = following.style.backgroundColor;
  const onMouseMove = (event) => {
    let x = event.offsetX;
    let y = event.offsetY;

    if (event.target.classList.contains('link-btn')) {
      following.style.transform = `translate(calc(${x}px - 50%), calc(${y}px - 150%)) scale(2)`;
      following.style.borderWidth = '1px';
      following.style.backgroundColor = 'transparent';
      following.style.borderColor = 'var(--cl-green)';
    }
  };

  const removeMouseMove = () => {
    following.style.transform = `translate(0, -50%) scale(1)`;
    following.style.borderWidth = '';
    following.style.backgroundColor = initBackgroundColor;
    following.style.borderColor = initBorderColor;
  };

  hovered.addEventListener('mousemove', onMouseMove);

  hovered.addEventListener('mouseleave', removeMouseMove);

  if (window.innerWidth < 1025) {
    hovered.removeEventListener('mousemove', onMouseMove);
    hovered.removeEventListener('mouseleave', removeMouseMove);
  }
};

window.hoverButton = followOnHover;

window.startButtonAnimation = function startButtonAnimation() {
  const buttons = document.querySelectorAll('.link-btn');
  if (buttons.length) {
    buttons.forEach((btn, i) => {
      let circle = btn.querySelector('.circle');
      if(!btn.classList.contains('start-circle-animation')) {
        followOnHover(btn, circle);
        btn.classList.add('start-circle-animation');
      }
    });
  }
};
startButtonAnimation();
