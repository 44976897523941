import Plyr from 'plyr';
import SimpleBar from 'simplebar';

import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/accordion.utills.js';

import './libs/countUp.lib.js';

import './components/buttons.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/speaker.component.js';
import './components/header.component.js';
import './components/jobs-search.component.js';
/*swiper*/
import './components/recent-podcasts.component.js';
import './components/diversity-swiper.component.js';
import './components/talent-swiper.component.js';
import './components/testimonials-swiper.component.js';
import './components/latest-roles.component.js';
import './components/clients-logo-swiper.component.js'
/*swiper end*/

// plyr
if (document.querySelector('.js-video-player')) {
  const videos = document.querySelectorAll('.js-video-player');

  videos.forEach((video) => {
    const player = new Plyr(video, {
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'captions',
        'fullscreen',
      ],
    });
  });
}
// end plyr

let userAgent = navigator.userAgent.toLowerCase();

let safari14 = /version\/14.*safari/gi.test(userAgent);

if (safari14) {
  document.querySelector('body').classList.add('safari-14-support');
}


  /*scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
  scrollLock.addScrollableSelector(document.querySelector('.simplebar-content-wrapper'));*/



window.closeLinksPopup = function closeLinksPopup(){
    scrollLock.enablePageScroll();
    $('.podcast-links-popup').fadeOut();
}
window.showLinksPopup = function showLinksPopup(link){

  let card  = link.closest('.podcast-card');
  let linkList = card.querySelector('.multiply-links');
  if(linkList){
   $('#links-popup').fadeIn({
     start: function(){
       $(this).find('.podcast-links-popup-list').html(linkList.innerHTML);
       scrollLock.disablePageScroll();
     }
   })
    return false;
  }
}
