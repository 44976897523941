window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
        "textarea[data-autosize]"
    );

    function auto_grow(element) {
      element.style.height = "80px";
      element.style.height = element.scrollHeight + 4 + "px";
    }
    autosizeTextareaElements.forEach(el => {
      el.addEventListener("input", function () {
        auto_grow(el)
      })
    })
  }
};
autosizeTextarea();
console.log('test')







