import Swiper, {Autoplay, Navigation} from 'swiper';
import {swiperObserver} from '../utils/swiper.utils.js';

let testimonialsSwiperWr = document.querySelectorAll('.testimonials-swiper-wr')
testimonialsSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let swiper = new Swiper(swiperEl, {
            modules: [Autoplay, Navigation],
            spaceBetween: 20,
            slidesPerView: 1,
            speed: 800,
            threshold: 10,
             autoplay: {
                 delay: 5000,
                 disableOnInteraction: false,
                 pauseOnMouseEnter: false,
             },
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {
                650:{
                    slidesPerView: 2,
                }
            }
        })
        swiperObserver(swiper);
    }
})