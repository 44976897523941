import Swiper, {Autoplay, Navigation} from 'swiper';
import {swiperObserver} from '../utils/swiper.utils.js';

let talentSwiperWr = document.querySelectorAll('.talent-swiper-wr')
talentSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let swiper = new Swiper(swiperEl, {
            modules: [Autoplay, Navigation],
            spaceBetween: 20,
            slidesPerView: 'auto',
            speed: 800,
            threshold: 10,
             autoplay: {
                 delay: 5000,
                 disableOnInteraction: false,
                 pauseOnMouseEnter: false,
             },
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {

                651:{
                    slidesPerView: 2,
                },
                1200:{
                    slidesPerView: 3,
                }
            }
        })
        swiperObserver(swiper);
    }
})