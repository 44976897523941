import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let recentPodcastJobsSwiperWr = document.querySelectorAll(
  '.latest-roles-section__slider'
);
recentPodcastJobsSwiperWr.forEach((swiperEl) => {
  if (swiperEl) {
    let nextEl = swiperEl.querySelector('.swiper-button-next');
    let prevEl = swiperEl.querySelector('.swiper-button-prev');
    let slidesCount = swiperEl.querySelectorAll('.swiper-slide').length;
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        426: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        590: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        952: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });
    swiperObserver(swiper);
  }
});
